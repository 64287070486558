<template>
    <b-card-code title="الخدمات الالكترونية" no-body>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <!-- filter -->
          <b-form-group
            label="فلتر"
            label-cols-sm="1"
            label-align-sm="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="md">
              <b-form-input
                id="filterInput"
                v-model="title"
                type="search"
                placeholder="ابحث عن .."
              />
              <b-input-group-append>
                <b-button @click="resetSearch()"> الغاء الفلتر </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  v-b-modal.model-a-w
                >
                  <feather-icon icon="FilePlusIcon" class="mr-50" />
                  <span class="align-middle">اضافة خدمة جديد</span>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          
        </div>
      </b-card-body>
  <div class="demo-vertical-spacing">
        <b-progress
        v-if="precentage !== 0"
          :value="precentage"
          max="100"
        />
        </div>
      <b-table
        ref="table"
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :items="items"
        :fields="fields"
      >
        <template #cell(action)="action">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-warning"
                v-b-modal.model-e-w
                @click="eRM(action.item)"
              >
                <feather-icon icon="EditIcon" class="mr-50" />
              </b-button>
            </b-col>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-danger"
                v-b-modal.modal-center
                @click="dRM(action.item)"
              >
                <feather-icon icon="Trash2Icon" class="mr-50" />
              </b-button>
            </b-col>
          </b-dropdown>
        </template>
        <template #cell(link)="link">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-info"
            @click="view(link.item.link)"
          >
            <feather-icon icon="FileTextIcon" class="mr-50" />
            <span class="align-middle">رابط الخدمة</span>
          </b-button>
        </template>
        <template #cell(image)="image">
          <b-avatar size="lg" :src="getImage(image.item.image)" />
        </template>
      </b-table>
  
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            @change="check()"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>
  
        <!-- pagination -->
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
          @change="check()"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-card-body>
      <b-modal
        id="model-e-w"
        ok-title="حفظ التعديلات"
        centered
        size="lg"
        title="تعديل الخدمة"
        @show="checkModel"
        @ok="uM"
        @hidden="rEF"
      >
        <b-form novalidate class="needs-validation">
          <b-form-row>
            <b-col md="12">
              <label for="input-valid1">العنوان :</label>
              <b-form-input
                id="input-valid1"
                v-model="editform.title"
                :state="editform.title.length > 0"
                placeholder="العنوان"
              />
            </b-col>
            <b-col md="12">
              <label for="input-valid1">التفاصيل:</label>
              <b-form-input
                id="input-valid1"
                v-model="editform.details"
                :state="editform.details.length > 0"
                placeholder="التفاصيل"
              />
            </b-col><b-col md="12">
              <label for="input-valid1">المودل:</label>
              <b-form-input
                id="input-valid1"
                v-model="editform.model"
                :state="editform.model.length > 0"
                placeholder="المودل"
              />
            </b-col>
            <b-col md="12">
              <label for="input-valid1">اختر صورة للخدمة:</label>
              <b-form-file
              placeholder="اختر الملف او اسقطها هنا..."
              drop-placeholder="اختر الملف او اسقطها هنا..."
                v-model="editform.file"
                @change="selectFileForEditForm"
              />
            </b-col>
          </b-form-row>
        </b-form>
      </b-modal>
      <b-modal
        id="model-a-w"
        ok-title="حفظ البيانات"
        centered
        size="lg"
        title="اضافة خدمة جديد"
        @show="checkModel"
        @ok="aNM"
        @hidden="rF"
      >
        <b-form novalidate class="needs-validation">
          <b-form-row>
            <b-col md="12">
              <label for="input-valid1">العنوان:</label>
              <b-form-input
                id="input-valid1"
                v-model="form.title"
                :state="form.title.length > 0"
                placeholder="العنوان "
              />
            </b-col>
            <b-col md="12">
              <label for="input-valid1">التفاصيل:</label>
              <b-form-input
                id="input-valid1"
                v-model="form.details"
                :state="form.details.length > 0"
                placeholder="التفاصيل"
              />
            </b-col>
            <b-col md="12">
              <label for="input-valid1">المودل:</label>
              <b-form-input
                id="input-valid1"
                v-model="form.model"
                :state="form.model.length > 0"
                placeholder="المودل"
              />
            </b-col>
            <b-col md="12">
              <label for="input-valid1">اختر الصورة:</label>
              <b-form-file
              placeholder="اختر الملف او اسقطها هنا..."
              drop-placeholder="اختر الملف او اسقطها هنا..."
                v-model="form.file"
                @change="selectFileForEditForm"
              />
            </b-col>
          </b-form-row>
        </b-form>
      </b-modal>
  
  <b-modal
    id="modal-center"
    centered
    title="حذف الخدمة"
    @ok="dM"
    ok-title="حذف الحقل"
  >
    <b-card-text>
      هل تريد حذف هذه الحقل  " {{ this.editform.title }} "
      ?
    </b-card-text>
  </b-modal>
  
  
    </b-card-code>
  </template>
  
  <script>
  import vSelect from "vue-select";
  import store from "@/store";
  import { togglePasswordVisibility } from "@core/mixins/ui/forms";
  import { filesUrl } from "@/main.js";
  import BCardCode from "@core/components/b-card-code/BCardCode.vue";
  import {
    BTable,
    BFormTags,
    BAvatar,
    BModal,
    BDropdown,
    BDropdownItem,
    BFormCheckboxGroup,
    BForm,
    BFormRow,
    BCol,
    VBModal,
    BBadge,BProgress,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BFormGroup,
    BFormCheckbox,
    BCardText,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BFormFile,
  } from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";
  import { quillEditor } from "vue-quill-editor";
  export default {
    components: {
      BCardCode,
      BTable,
      BForm,
      quillEditor,
      vSelect,
      BAvatar,BProgress,
      BBadge,
      BFormTags,
      BFormRow,
      BDropdown,
      filesUrl,
      BDropdownItem,
      BFormGroup,
      togglePasswordVisibility,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormCheckboxGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
      BCardBody,
      BModal,
      VBModal,
      Ripple,
      BCol,
      BFormCheckbox,
      BCardText,
      BFormValidFeedback,
      BFormInvalidFeedback,
      BFormFile,
    },
    data() {
      return {
        check:[],
        precentage:0,
        teacher_id: "",
        perPage: 3,
        pageOptions: [3, 5, 10, 100],
        totalRows: 1,
        currentPage: 0,
        department: "",
        title: "",
        filter: null,
        filterOn: [],
        departments: [],
        teachers: [],
        stages: [],
        fields: [
          {
            key: "title",
            label: "العنوان بالعربي",
          },
          {
            key: "details",
            label: "العنوان بالانكليزي",
          },
          {
            key: "model",
            label: "رابط الخدمة ",
          },
          { key: "image", label: "الصورة " },
          { key: "action", label: "الاجراءات" },
        ],
        items: [],
        form: {
          title: "",
          details: "",
          file: "",
          model: "",
        },
        editform: {
        title: "",
        details: "",
          file: "",
          model: "",
          id: null,
        },
        selected: [],
        selected2: "",
        formModal: {},
        snowOption: {
          theme: "snow",
        },
        slider:"",
      };
    },
    watch: {
      department: function (val) {
        this.gS();
      },
      title: function (val) {
        this.gS();
      },
    },
    mixins: [togglePasswordVisibility],
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
      },
    },
    directives: {
      Ripple,
    },
    mounted() {
      this.ID(), this.gS(),this.gSM()
    },
    methods: {
      async gSM() {
        await this.axios
          .get(
            `since/deparment?take=${this.perPage}&skip=${
              this.currentPage - 1
            }&select=true`
          )
          .then((res) => {
            this.departments = [];
            this.departments = res.data.items;
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "danger",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
      },
      checkModel() {
        this.selected = [];
      },
      resetCheckboxes() {
        this.selected = [];
      },
      async uM() {
        let data = new FormData();
        data.append("title", this.editform.title);
        data.append("details", this.editform.details);
        data.append("model", this.editform.model);
        data.append("image", this.editform.file);
        await this.axios
          .post(`services/${this.editform.id}`, data,{
            onUploadProgress: progressEvent => {
              this.precentage =
                (progressEvent.loaded / progressEvent.total) * 100;
            }
          })
          .then((res) => {
            if (res.data.errors) {
              this.errorToast();
            } else {
              this.gS();
              this.makeToast(
                "success",
                "update Success",
                "Your update is done successfully"
              );
            }
            this.precentage=0
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "warning",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
      
      },
      async aNM() {
        let data = new FormData();
        data.append("title", this.form.title);
        data.append("details", this.form.details);
        data.append("model", this.form.model);
        data.append("image", this.form.file);
        await this.axios
          .post(`services`, data,{
            onUploadProgress: progressEvent => {
              this.precentage =
                (progressEvent.loaded / progressEvent.total) * 100;
            }
          })
          .then((res) => {
            if (res.data.errors) {
              this.errorToast();
            } else {
              this.gS();
              this.makeToast(
                "success",
                "update Success",
                "Your model is added successfully"
              );
            }
            this.rF();
            this.precentage=0
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "warning",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
        this.rF();
      },
      rF() {
        (this.form.title = ""),
          (this.form.details = ""),
          (this.form.file = ""),
          (this.form.model = "")
      },
      rEF() {
        (this.form.title = ""),
          (this.form.details = ""),
          (this.form.file = ""),
          (this.form.model = ""),
          (this.editform.id = "");
      },
      async dM() {
        await this.axios
          .delete(`services/${this.editform.id}`)
          .then((res) => {
            if (res.data.errors) {
              this.errorToast();
            } else {
              this.gS();
              this.makeToast(
                "success",
                "Delete Success",
                "Your item is deleted successfully"
              );
            }
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "warning",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
      },
      errorToast() {
        this.makeToast(
          "danger",
          "update failed",
          "some thing want wrong, i`m sorry :("
        );
      },
      eRM(item) {
        this.editform.title = item.title;
        this.editform.details = item.details;
        this.editform.model = item.model;
        this.editform.en_description = item.en_description;
        this.editform.file = item.image;
        this.editform.id = item.id;
      },
      dRM(item) {
        this.editform.title = item.title;
        this.editform.id = item.id;
      },
      check() {
        setTimeout(() => this.gS(), 1000);
      },
      ID() {
        this.teacher_id = localStorage.getItem("NisourTeacherId");
      },
      async gS() {
        await this.axios
          .get(`services?take=${this.perPage}&skip=${this.currentPage - 1}`)
          .then((res) => {
            this.items = [];
            this.totalRows = res.data.totalCount;
            this.items = res.data.items;
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "danger",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
      },
      makeToast(variant = null, title = null, body = null) {
        this.$bvToast.toast(body, {
          title: title,
          variant,
          solid: false,
        });
      },
      resetSearch() {
        (this.title = ""), (this.department = "");
        this.gS();
      },
      getImage(path) {
        return `${filesUrl}/${path}`;
      },
      selectFilesForForm(e) {
        this.form.files = [];
        console.log(e.target.files);
        console.log(e.target.files[0]);
        e.target.files.forEach((element) => this.form.files.push(element));
        console.log(this.form.files);
      },
      selectFilesForEditForm(e) {
        this.editform.files = [];
        console.log(e.target.files);
        console.log(e.target.files[0]);
        e.target.files.forEach((element) => this.editform.files.push(element));
        console.log(this.form.files);
      },
      selectFileForForm(e) {
        this.form.files = "";
        console.log(e.target.files);
        console.log(e.target.files[0]);
        this.form.files = e.target.files[0];
        console.log(this.form.files);
      },
      selectFileForEditForm(e) {
        this.editform.file = "";
        console.log(e.target.files);
        console.log(e.target.files[0]);
        this.editform.file = e.target.files[0];
        console.log(this.editform.file);
      },
    },
  };
  </script>
  <style lang="scss">
  @import "@core/scss/vue/libs/quill.scss";
  </style>